body {
  // --ion-background-color: linear-gradient(45deg, #f5e4ee 0%, #fefef2 40%);
  --ion-background-color: #fff;

  &.loggedin {
    --ion-background-color: #fff;
  }
}

.md,
.ios {
  @media (prefers-color-scheme: dark) {
    body {
      // --ion-background-color: linear-gradient(45deg, #f5e4ee 0%, #fefef2 40%);
      // --ion-background-color: var(--gradient-color);
      --ion-background-color: #fff;

      &.loggedin {
        --ion-background-color: #fff;
      }
    }
  }
}

.wrap {
  max-width: 650px;
  margin: 0 auto;
  padding: 0 35px;
}

:root {
  --background-activated: #a6307c;
  --background-focused: #bd3b8f;
  --background-hover: #db49a8;
}

ion-toolbar {
  --background: #f7f7f7;
  --border-color: #c8c8c8;

  ion-menu-button,
  ion-back-button {
    color: #a6307c;
  }

  ion-title {
    color: #a6307c;
    font: 700 16px "Inter", sans-serif;
    letter-spacing: -0.408px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}

ion-list {
  background: transparent !important;
  --background: transparent !important;
}

.modal-mic {
  background-size: cover;
  display: none;
  flex-direction: column;
  height: 100%;
  left: 0;
  padding: 20px 20px 0;
  position: fixed;
  top: 0;
  z-index: 10000;
  width: 100%;

  .bg {
    background-size: cover;
    height: 100%;
    left: 0;
    opacity: 0.95;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  .tap,
  .close-modal {
    display: inline-flex;
  }

  .tap {
    align-items: center;
    flex-direction: column;

    .icon {
      font-size: 46px;
    }
  }

  .replay {
    align-items: center;
    color: #a6307c;
    display: flex;
    font: 500 35px "Inter", sans-serif;
    justify-content: center;
  }

  .center-mic {
    align-items: center;
    color: #a6307c;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font: 500 32px "Inter", sans-serif;
    justify-content: center;
  }

  .remove {
    color: #a6307c;
    font-size: 80px;
    line-height: 1;
    text-align: center;
  }

  &.active {
    display: flex;
  }
}

ion-button {
  --background: #a6307c;
  --border-radius: 12px;
  --box-shadow: none;
  font: 700 16px "Inter", sans-serif;
  height: 43px;
  letter-spacing: -0.408px;
  text-transform: initial;

  &:active,
  &:hover,
  &:focus,
  &.ion-activated {
    --background: #db49a8;
    --background-activated: #db49a8;
  }

  &.button-clear {
    --background: transparent;
    height: 37px;
    width: 37px;
    --padding-start: 0;
    --padding-end: 0;
  }
}

.content-view {
  --background: #fff;

  .head {
    background: #fff8ea;
    border-bottom: 0.25px solid #d5ac95;
    border-top: 0.25px solid #d5ac95;
    padding: 10px 25px;

    h2 {
      color: #a6307c;
      font: 600 20px/1.6 "Inter", sans-serif;
      letter-spacing: -0.408px;
      margin: 0;
      text-align: center;
    }

    .bar {
      margin-top: 10px;

      .bar-inner {
        height: 10px;
        border: 1px solid #a6307c;
        border-radius: 20px;
        overflow: hidden;

        .fill {
          background: #a6307c;
          width: 10%;
          display: inline-block;
          height: 100%;
          vertical-align: top;
        }
      }
    }
  }

  .wrap-content {
    margin: 0 auto;
    max-width: 400px;
    padding: 0 40px;

    p {
      color: #000000;
      font: 16px/1.88 "Inter", sans-serif;
      letter-spacing: -0.408px;
      margin: 20px 0 30px;
    }

    .wine {
      color: #a6307c;
    }
  }
}

.create-add-footer {
  --background: #fff;
  background: #fff;
  border: 0 none;
  padding: 10px 20px 30px;
  text-align: center;

  ion-button {
    margin: 0 auto;
    max-width: 335px;
  }

  &:before {
    display: none !important;
  }
}

.create-form {
  h3 {
    color: #000000;
    font: 18px/1.67 "Inter", sans-serif;
    letter-spacing: -0.408px;
  }

  ion-item {
    --background: transparent;
    --border-color: #dbb2cc !important;
    padding-top: 20px;

    ion-label {
      --color: #4e4e4e !important;
      color: #4e4e4e !important;
      font: 300 16px/1.4 "Inter", sans-serif !important;
      letter-spacing: -0.408px;
      margin-bottom: 8px;
      transform: none !important;
    }

    ion-input,
    ion-textarea {
      --background: transparent;
      --color: #a6307c;
      letter-spacing: -0.408px;
      font: 16px/2 "Inter", sans-serif;
      margin-top: 0px;
      --placeholder-color: #3f3f3f;

      &::placeholder {
        color: #3f3f3f;
        --placeholder-color: #3f3f3f;
      }
    }

    ion-textarea {
      --background: #fffcf7;
      border: 0.25px solid #d4ac95;
      border-radius: 6px;
      padding-left: 10px;
      padding-right: 10px;
    }

    &.item-has-focus {
      --highlight-background: #a6307c;
    }

    &.custom-radio {
      background: #fffcf6;
      margin-bottom: 10px;
      padding-top: 0;
      border: 0.25px solid #d4ac95;
      border-radius: 6px;

      ion-label {
        color: #a6307c !important;
        font: 500 16px "Inter", sans-serif !important;
        letter-spacing: -0.408px;
        align-self: center;
      }

      ion-radio,
      ion-checkbox {
        --background: #fffcf6;
        height: 30px;
        width: 30px;
        margin-right: 10px;
      }

      ion-radio::part(container) {
        border: 0.5px solid #a6307c;
        border-radius: 3px;
      }

      ion-checkbox::part(container) {
        border: 0.5px solid #a6307c;
        border-radius: 3px;
      }

      ion-radio::part(mark) {
        background: none;
        transition: none;
        transform: none;
        border-radius: 0;
      }

      ion-checkbox::part(mark) {
        background: none;
        transition: none;
        transform: none;
        border-radius: 0;
      }

      ion-radio.radio-checked::part(container) {
        background: #a6307c;
        border-color: transparent;
      }

      ion-radio.radio-checked::part(mark) {
        width: 6px;
        height: 10px;
        border-width: 0px 2px 2px 0px;
        border-style: solid;
        border-color: #fff;
        transform: rotate(45deg);
      }

      ion-checkbox.checkbox-checked::part(container) {
        background: #a6307c;
        border-color: transparent;
        padding: 5px;
      }

      ion-checkbox.checkbox-checked::part(mark) {
        width: 6px;
        height: 10px;
        border-width: 0px 2px 2px 0px;
        border-style: solid;
        border-color: #fff;
        font-size: 10px;
        // transform: rotate(45deg);
      }
    }

    &.nopadlr {
      padding-top: 0;
      --inner-padding-end: 0;
      --inner-padding-start: 0;

      &::part(native) {
        padding-left: 0;
      }
    }
  }
}

.chat {
  align-items: center;
  background: #fffcf6;
  border: 0.25px solid #d4ac95;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  width: 100%;

  .chat-meta {
    color: #000000;
    font: 16px "Inter", sans-serif;
    letter-spacing: -0.408px;
    flex-grow: 1;
    width: 70%;

    .date-time {
      color: #3f3f3f;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .chat-name {
      &.text-wrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .mood-icon {
    margin-left: 10px;

    .add {
      color: #a6307c;
      display: flex;
      font-size: 30px;
    }
  }

  &.flex-col {
    flex-direction: column;
  }
}

ion-footer {
  &.chat-footer {
    padding-bottom: 20px;

    ion-toolbar {
      height: auto;
    }

    ion-buttons {
      align-items: baseline;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;

      ion-icon {
        font-size: 32px;
      }

      ion-button {
        --color: #a6307c;
        color: #a6307c;
        font: 500 14px "Inter", sans-serif;
        height: auto;
        letter-spacing: -0.408px;
        min-width: 75px;
        width: auto;

        .inner {
          align-items: center;
          display: flex;
          flex-direction: column;
          padding: 10px 0;

          .text {
            margin-top: 5px;
          }

          &.more-chat {
            flex-direction: row;

            ion-icon {
              font-size: 18px;
              position: relative;
              top: 2px;
            }
          }
        }
      }
    }
  }
}

.px16 {
  padding: 0 16px;
}

.mood-selector-modal {
  ion-col {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  ion-button {
    // &.mood-button {
    //   display: flex;
    //   flex-direction: column;
    //   width: 100%;
    //   &::part(native) {
    //     display: flex;
    //     justify-content: start;
    //   }
    // }
    height: auto;
    width: auto;

    .inner-btn {
      display: flex;
      align-items: center;

      .icon {
        align-items: center;
        display: flex;
        flex: 0 0 32px;
        justify-content: center;
        height: 32px;
        margin-right: 10px;
        width: 32px;
        font-size: 30px;
      }
    }
  }
}

ion-item {
  --background: white;
  color: black;
  margin-bottom: 10px;

  &.search {
    margin-top: 5px;
    position: relative;

    .mic {
      align-items: center;
      color: #838388;
      display: flex;
      font-size: 24px;
      height: 40px;
      justify-content: center;
      position: absolute;
      right: 16px;
      top: 0;
      width: 40px;
    }

    ion-searchbar {
      --background: rgba(118, 118, 128, 0.12);
      --box-shadow: none;
      --border-radius: 10px;
      --placeholder-color: rgba(60, 60, 67, 0.6);
      --placeholder-opacity: 1;
      color: #a6307c;
      font: 16px "Inter", sans-serif;
      height: 40px;
      letter-spacing: -0.408px;
      padding: 0;
    }
  }
}

.alert-note {
  --background: #e5e5e5;

  .alert-title {
    --ion-text-color: #a6307c;
  }

  textarea {
    color: #a6307c;
  }

  button {
    --ion-color-primary: #a6307c;
  }
}

ion-popover {
  &.mood-select {
    --width: fit-content;

    ion-item {
      border-bottom: 1px solid #d7d7d7;
      margin-bottom: 0;

      ion-img {
        height: 30px;
        width: 30px;
      }
    }
  }

  &.month-select {
    --width: 120px;

    ion-item {
      border-bottom: 1px solid #d7d7d7;
      font: 16px "Inter", sans-serif;
      margin: 0;
    }
  }
}

ion-modal {
  ion-header {
    ion-toolbar {
      padding-top: 0px !important;
    }
  }

  ion-content {
    --background: white;
  }
}

// ion-datetime theming
:root {
  --ion-color-pointmenorth: #a6307c;
  --ion-color-pointmenorth-rgb: 166, 48, 124;
  --ion-color-pointmenorth-contrast: #ffffff;
  --ion-color-pointmenorth-contrast-rgb: 255, 255, 255;
  --ion-color-pointmenorth-shade: #922a6d;
  --ion-color-pointmenorth-tint: #af4589;
}

.ion-color-pointmenorth {
  --ion-color-base: var(--ion-color-pointmenorth);
  --ion-color-base-rgb: var(--ion-color-pointmenorth-rgb);
  --ion-color-contrast: var(--ion-color-pointmenorth-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pointmenorth-contrast-rgb);
  --ion-color-shade: var(--ion-color-pointmenorth-shade);
  --ion-color-tint: var(--ion-color-pointmenorth-tint);
}

/* iOS theme for ion-datetime */
ion-datetime#myCal {
  // center calendar
  min-width: 80%;
  margin: auto;
  --background: #fff;
  --background-rgb: rgb(255, 255, 255);
  color: #a6307c;
  --ion-text-color: #a6307c;
  --ion-text-color-rgb: 166, 48, 124;
  --ion-color-step-150: #f2e0eb;
  // for IOS day name
  --ion-color-step-300: #3c3c434d;
  // for MD year selection
  --ion-color-base: #922a6d;
  // for MD day name
  --ion-color-step-500: #3c3c434d;
  // title color
  --ion-color-step-650: #a6307c;
}

// mood-selector styling
ion-modal.mood-selector-modal {
  --ion-background-color: #fff;
  --width: fit-content;
  --min-width: 330px;
  --border-radius: 6px;
  --height: fit-content;

  ion-header {
    display: flex;

    ion-title {
      font-size: 18px;
      line-height: 1;
      padding-left: 10px;
      text-align: left;

      .title {
        display: flex;
        align-items: center;

        .icon {
          display: inline-flex;
          margin-right: 10px;
        }
      }
    }

    ion-button {
      color: #a6307c;
      --color: #a6307c;
    }
  }

  ion-content {
    min-height: 330px;

    ion-label {
      letter-spacing: -0.408px;
      color: #3f3f3f;
      font: 16px "Inter", sans-serif;
    }
  }
}

#north-model-selector-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  padding: 0 40px;
}

.fieldmeta {
  background: #ffffff;
  border: 5px solid #d9d9d9;
  margin-bottom: 20px;
  padding: 20px;
}

.limit-width {
  max-width: 650px;
  margin: 0 auto;
}