/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "assets/scss/app.scss";

body {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

ion-select {
  &::part(text) {
    color: #666;
    font: 16px/1.88 "Inter", sans-serif;
    letter-spacing: -0.408px;
  }
}

ion-menu {
  &::part(container) {
    @media (min-width: 768px) {
      --max-width: auto;
      --min-width: auto;
    }

    --max-width: 85%;
    --min-width: 85%;
  }
}

.logo-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a6307c;
  font: 500 19px/1 "Inter", sans-serif;
  letter-spacing: -0.408px;
}

.setting-box {
  border-bottom: 0.5px solid #c8c8c8;
  padding: 20px;

  h2 {
    color: #3f3f3f;
    font: 18px/1 "Inter", sans-serif;
    letter-spacing: -0.408px;
    margin-bottom: 25px;
  }
}

.notification-box {
  border-bottom: 0.5px solid #c8c8c8;
  padding: 20px;

  h2 {
    color: #a6307c;
    font: 18px/1 "Inter", sans-serif;
    letter-spacing: -0.408px;
    margin: 0 0 20px;
  }

  h3,
  .flex-add {
    color: #3f3f3f;
    font: 16px "Inter", sans-serif;
    letter-spacing: -0.408px;
    margin: 0 0 10px;
  }

  .flex-add {
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin-top: 25px;
  }

  .notification-meta {
    background: #fffcf6;
    border: 0.25px solid #d4ac95;
    border-radius: 6px;
    min-height: 80px;
    margin-top: 20px;
    padding: 15px 30px;
  }

  .placeholder {
    color: #a5a5a5;
    font: 16px "Inter", sans-serif;
    letter-spacing: -0.408px;
  }
}

ion-toggle {
  --background: #e4c0d7;
  --background-checked: #a6307c;
  --handle-background: #a6307c;
  --handle-background-checked: #e4c0d7;
}

ion-modal#subscriptionModal {
  --height: 80%;
  --min-height: 500px;
  --width: 95%;
  --max-width: 500px;
  --min-width: 200px;
  --border-radius: 15px;
  --position: fixed;

  &::part(content) {
    position: absolute;
    bottom: 10px;
  }
}